@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap);
:root {
  font-size: 16px;
  font-family: "Roboto", sans-serif;

  --primary: #2773ff;
  --primary-dark: #1b51b3;
  --text-primary: #0f2744;
  --light-grey: #e6eaee;
  --desaturated-blue: #728095;
  --dollar-bg: #d4e3ff80;
  --red: #f05454;
  --red-10: #F0545419;
}

html,
body,
h1,
h2,
h3,
h4,
p,
ul,
ol {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 2.25rem;
}

body {
  min-height: 100vh;
  color: #0f2744;
  color: var(--text-primary);
}

button:hover {
  cursor: pointer;
}

.container {
  box-sizing: border-box;
  padding: 20px;
  margin: 0 auto;
  min-height: 100vh;
  max-width: 600px;
}

.Details_row__PWV4g {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.Details_backButton__wRjps {
    padding: 8px;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    background-color: var(--primary);
}

.Details_backButton__wRjps:hover {
    background-color: var(--primary-dark);
}

.Details_sectionMargin__11pwG {
    margin: 50px 0 0 0;
}

.Details_totalCostsContainer__2tZnV {
    font-size: 1rem;
    font-weight: 700;
    margin: 10px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.Details_totalCosts__2UfOg {
    display: inline-block;
    margin-left: 10px;
    padding: 10px 20px;
    color: var(--red);
    background-color: var(--red-10);
    border-radius: 8px;
}
.Cost_costContainer__GaT8F {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin: 5px 0;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.Cost_costDescription__zKrXh {
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
  max-width: 60%;
}

.Cost_cost__3H4X- {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  font-weight: 500;
  color: var(--red);
}


.Cost_costContainer__GaT8F:hover div[aria-label="tooltip"] {
  display: block;
}
.Tooltip_container__3ToI7 {
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: -1rem;
  right: 0;
  background-color: var(--light-grey);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  display: none;
}

.Tooltip_container__3ToI7 span {
  margin: 0 4px;
  padding: 4px;
  border-radius: 4px;
  color: var(--light-grey);
  background-color: var(--desaturated-blue);
}

.Footer_footer__Lgjqd {
  margin-top: 5rem;
  padding: 1.5rem;
  border-radius: 8px;
  color: var(--desaturated-blue);
  background-color: var(--light-grey);
}

.Footer_footer__Lgjqd > a {
  color: var(--text-primary)  ;
  font-weight: 700;
}
.SalaryBlock_salaryContainer__3HP6w {
  max-width: 267px;
  margin: 20px auto;
  padding: 16px;
  border-radius: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
}

.SalaryBlock_salaryContainer__3HP6w:first-child {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.SalaryBlock_details__2h0hm {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.SalaryBlock_details__2h0hm span:first-child {
  font-size: 0.625rem;
  color: var(--desaturated-blue);
}

.SalaryBlock_details__2h0hm span:last-child {
  font-weight: 500;
  font-size: 1.25rem;
  color: var(--text-primary);
}

.SalaryBlock_dollarContainer__8dLkT {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--dollar-bg);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Input_grossSalaryContainer__2BSdV {
  width: 85%;
  max-width: 375px;
  box-sizing: border-box;
  margin: 15% auto 0 auto;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
}

.Input_grossSalaryContainer__2BSdV label {
  font-weight: 500;
  color: var(--desaturated-blue);
}

.Input_grossSalaryContainer__2BSdV input {
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
  padding: 12px 10px;
  font-size: 1.125rem;
  border-radius: 4px;
  border: 2px solid var(--light-grey);
}

.Input_grossSalaryContainer__2BSdV input:focus {
  outline: none;
  border: 2px solid var(--desaturated-blue);
}

.Input_continue__3gX24 {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border: none;
  border-radius: 4px;
  color: white;
  background-color: var(--primary);
  margin-top: 30px;
  padding: 10px 0;
}

.Input_continue__3gX24:hover {
  background-color: var(--primary-dark);
}

