@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");

:root {
  font-size: 16px;
  font-family: "Roboto", sans-serif;

  --primary: #2773ff;
  --primary-dark: #1b51b3;
  --text-primary: #0f2744;
  --light-grey: #e6eaee;
  --desaturated-blue: #728095;
  --dollar-bg: #d4e3ff80;
  --red: #f05454;
  --red-10: #F0545419;
}

html,
body,
h1,
h2,
h3,
h4,
p,
ul,
ol {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 2.25rem;
}

body {
  min-height: 100vh;
  color: var(--text-primary);
}

button:hover {
  cursor: pointer;
}

.container {
  box-sizing: border-box;
  padding: 20px;
  margin: 0 auto;
  min-height: 100vh;
  max-width: 600px;
}