.grossSalaryContainer {
  width: 85%;
  max-width: 375px;
  box-sizing: border-box;
  margin: 15% auto 0 auto;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
}

.grossSalaryContainer label {
  font-weight: 500;
  color: var(--desaturated-blue);
}

.grossSalaryContainer input {
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
  padding: 12px 10px;
  font-size: 1.125rem;
  border-radius: 4px;
  border: 2px solid var(--light-grey);
}

.grossSalaryContainer input:focus {
  outline: none;
  border: 2px solid var(--desaturated-blue);
}

.continue {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border: none;
  border-radius: 4px;
  color: white;
  background-color: var(--primary);
  margin-top: 30px;
  padding: 10px 0;
}

.continue:hover {
  background-color: var(--primary-dark);
}
