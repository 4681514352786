.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.backButton {
    padding: 8px;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    background-color: var(--primary);
}

.backButton:hover {
    background-color: var(--primary-dark);
}

.sectionMargin {
    margin: 50px 0 0 0;
}

.totalCostsContainer {
    font-size: 1rem;
    font-weight: 700;
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.totalCosts {
    display: inline-block;
    margin-left: 10px;
    padding: 10px 20px;
    color: var(--red);
    background-color: var(--red-10);
    border-radius: 8px;
}