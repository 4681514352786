.container {
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: -1rem;
  right: 0;
  background-color: var(--light-grey);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  display: none;
}

.container span {
  margin: 0 4px;
  padding: 4px;
  border-radius: 4px;
  color: var(--light-grey);
  background-color: var(--desaturated-blue);
}
