.salaryContainer {
  max-width: 267px;
  margin: 20px auto;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
}

.salaryContainer:first-child {
  transform: scale(1.1);
}

.details {
  display: flex;
  flex-direction: column;
}

.details span:first-child {
  font-size: 0.625rem;
  color: var(--desaturated-blue);
}

.details span:last-child {
  font-weight: 500;
  font-size: 1.25rem;
  color: var(--text-primary);
}

.dollarContainer {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--dollar-bg);
  display: flex;
  align-items: center;
  justify-content: center;
}
