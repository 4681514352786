.footer {
  margin-top: 5rem;
  padding: 1.5rem;
  border-radius: 8px;
  color: var(--desaturated-blue);
  background-color: var(--light-grey);
}

.footer > a {
  color: var(--text-primary)  ;
  font-weight: 700;
}