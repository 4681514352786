.costContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.costDescription {
  flex: 0 1 auto;
  max-width: 60%;
}

.cost {
  flex: 0 0 auto;
  font-weight: 500;
  color: var(--red);
}


.costContainer:hover div[aria-label="tooltip"] {
  display: block;
}